// eslint-disable-next-line @typescript-eslint/no-var-requires
const FormData = require('form-data')
import { getUrl } from 'API/getUrl'
import { listGroups } from 'API/group'
import { addToGroup } from 'API/user'
import axios from 'axios'

import React, { Fragment, useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { ApplicationStateActions } from 'redux/slices/ApplicationState'

import './table.scss'
import { UserAdditionMetaData } from '../../types/UserAdditionMetaData'
import { navigate } from 'gatsby'

const GroupComponent: React.FC<{ setConfirmed: CallableFunction }> = ({ setConfirmed }) => {
	const [groups, setGroups] = useState<string[]>([])
	const [newGroup, setNewGroup] = useState('')
	const selectedGroup = useAppSelector((state) => state.ApplicationReducer.selectedGroup)
	const uids = useAppSelector((state) => state.ApplicationReducer.uids)

	const dispatch = useAppDispatch()

	useEffect(() => {
		const run = async () => {
			const groups = await listGroups()
			if (groups) setGroups(groups.map((elem) => elem.name))
		}
		run()
	}, [])

	async function handleAdd(selectedGroup: string) {
		dispatch(ApplicationStateActions.setState({ selectedGroup }))
		await addToGroup({ group_name: selectedGroup, uids })
		setConfirmed()
		navigate(`/admin/group/${selectedGroup}`)
	}

	return (
		<Fragment>
			{groups.map((elem, index) => {
				return (
					<div key={elem + index} onClick={() => dispatch(ApplicationStateActions.setState({ selectedGroup: elem }))} className='row'>
						<div className={`elem ${selectedGroup === elem && 'selected'}`}>{elem}</div>
					</div>
				)
			})}

			<div className='row'>
				<div className='wrap'>
					<form
						onSubmit={(e) => {
							e.preventDefault()
							handleAdd(newGroup)
						}}
					>
						<input
							onChange={(e) => setNewGroup(e.target.value)}
							type='text'
							name='name'
							id='name'
							placeholder='Add Group'
							value={newGroup}
							required
						/>
						<button type='submit'>Add</button>
					</form>
				</div>
			</div>
		</Fragment>
	)
}

export const UploadUsers = () => {
	const ref = useRef<HTMLFormElement>(null)
	const [state, setState] = useState<{ [key: string]: any }>({})
	const [batch, setBatch] = useState<UserAdditionMetaData>()
	const [confirmed] = useState(false)
	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(ApplicationStateActions.setState({ uids: batch?.success.map((elem) => elem.uid) }))
		// console.log({ batch })
	}, [batch])
	const selectedGroup = useAppSelector((state) => state.ApplicationReducer.selectedGroup)
	const uids = useAppSelector((state) => state.ApplicationReducer.uids)

	return (
		<Fragment>
			Add Users:
			<form
				ref={ref}
				onSubmit={async (e) => {
					e.preventDefault()
					const form = new FormData()
					// console.log({ state })
					if (state?.['file']) {
						form.append('file', state?.['file'])
						// console.log({ form })
						try {
							const batchAdd = await axios.post<UserAdditionMetaData>(getUrl('user/batch'), form, {
								headers: {
									'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
									Authorization: localStorage.getItem('token'),
								},
							})
							setBatch(batchAdd.data)
						} catch (error) {
							console.error((error as any)?.response?.data?.message)
							console.error((error as any)?.response)
						}
					}
				}}
			>
				<input
					onChange={(e) => {
						const files = e.target.files
						if (files !== null) {
							setState((state) => ({
								...state,
								file: files[0],
							}))
						}
					}}
					type='file'
					name='file'
					id='file'
				/>
				<input type='submit' value='Submit' />
			</form>
			{!confirmed && batch && (
				<div className='outer'>
					<div className='table'>
						<>
							<div className='row'>
								<div className='elem'>Email</div>
							</div>
							{batch.success.map((elem, index) => {
								return (
									<Fragment key={elem.email + index}>
										<div key={elem.email + index} className='row'>
											<div className='elem success'>{elem.email}</div>
										</div>
									</Fragment>
								)
							})}

							{batch.failed.map((elem, index) => {
								return (
									<Fragment key={elem.email + index}>
										<div key={elem.email + index} className='row'>
											<div className='elem failed'>{elem.email}</div>
										</div>
									</Fragment>
								)
							})}
						</>
					</div>
					<div className='separator'>
						<button
							onClick={async () => {
								try {
									if (selectedGroup) {
										await addToGroup({ group_name: selectedGroup, uids })
										// setConfirmed(true)
										navigate(`/admin/group/${selectedGroup}`)
									}
								} catch (err) {
									console.error(err)
								}
							}}
							className=''
						>
							{'Confirm and Add to Group >>>'}
						</button>
						{/* <button className=''>{'Reject and Remove Added'}</button> */}
					</div>
					<div className='table'>
						<GroupComponent
							setConfirmed={() => {
								// setConfirmed(true)
							}}
						></GroupComponent>
					</div>
				</div>
			)}
		</Fragment>
	)
}
