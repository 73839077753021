import { UploadUsers } from 'components/admin/UploadUsers'
import React from 'react'

const BatchAddUser: React.FC = () => {
	return (
		<>
			<h1>Add Users CSV</h1>

			<UploadUsers></UploadUsers>
		</>
	)
}
export default BatchAddUser
